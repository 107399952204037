<template>
    <div class="wrapper">
        <sidebar></sidebar>

        <div class="main">
            <navbar></navbar>

            <main class="content">
                <router-view></router-view>
            </main>

<!--            <footer-section></footer-section>-->
        </div>
    </div>
</template>

<script>
import FooterSection from "@/layouts/FooterSection";
import Sidebar from "@/layouts/Sidebar";
import Navbar from "@/layouts/Navbar";

export default {
    name: "MainLayout",
    components: {FooterSection, Sidebar, Navbar},
}
</script>

<style scoped>

</style>